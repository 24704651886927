import { Box, Typography } from "@mui/material";
import { colors } from "../../../../theme";
import { nFormatter } from "../../../../utils";
import { ISelectableItem } from "../../../../components/SelectItems/interfaces";

export const VRChartCustomTooltip = (props: any) => {
  let {
    active,
    payload,
    coverageDataMap,
    multiItems,
  }: {
    active: boolean;
    payload: any;
    coverageDataMap: Map<string, number>;
    multiItems: ISelectableItem[];
  } = props;

  if (active && payload?.length) {
    let name = payload[0].payload.name;
    let coverageValue: number = 0;
    if (coverageDataMap) {
      coverageValue = coverageDataMap?.get(name) ?? 0;
    }
    return (
      <Box
        id="vr-chart-tooltip"
        style={{
          backgroundColor: "#fff",
          minWidth: 100,
          padding: 10,
          borderRadius: 8,
          border: `1px solid ${colors.lightBlue}`,
          color: "#00385D",
          fontSize: "0.9rem",
        }}
      >
        <Typography
          id="tooltip-name"
          style={{
            color: "#001738",
            marginTop: 0,
            marginBottom: "20px",
            fontWeight: "600",
          }}
        >
          {name}
        </Typography>
        {multiItems?.length > 0 ? (
          multiItems?.map((item) => (
            <Typography id="tooltip-item" key={item.id}>
              {item.name}: {nFormatter(payload[0].payload[item.selectionName], 2, false, true)}
            </Typography>
          ))
        ) : (
          <Typography id="tooltip-value" >{nFormatter(payload[0].payload.value, 2, false, true)}</Typography>
        )}
        {coverageDataMap && (
          <Typography
            id="tooltip-coverage"
            sx={{
              color: `${coverageValue < 26 ? colors.secRed100 : colors.secGreen100}`,
            }}
          >
            {nFormatter(coverageValue, 2, false, false)}%
          </Typography>
        )}
      </Box>
    );
  }
  return null;
};

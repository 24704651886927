import { Box, Grid, Typography, Button } from "@mui/material";
import getSymbolFromCurrency from "currency-symbol-map";
import { useSelector, useDispatch } from "react-redux";
import { switchSummaryCurrency } from "../advancedReportSlice";
import { colors } from "../../../../theme";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { BasicTooltip } from "../../../../components/BasicTooltip/BasicTooltip";
import ConditionalWrapper from "../../../../components/chartTemplates/chartHelpers/ConditionalWrapper";

export const EllipsisCell = (props) => {
  return (
    <Box
      className="contentCell"
      sx={{
        display: "table",
        tableLayout: "fixed",
        width: "100%",
        textAlign: "center",
      }}
    >
      <Box
        className="ellipsisCell"
        sx={{
          display: "table-cell",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export function CustomSummary({ summaryRowsT, hc: hiddenColumns, columnsModel }) {
  const dispatch = useDispatch();
  const { summaryCurrency } = useSelector((state) => state.advancedReport);
  const summaryRowNamesCurrencyDependent = [
    "avg_cpma",
    "avg_cpmb",
    "abRevActualRateLift",
    "abRevRateLift",
    "avgCpmRateLift",
    "abRevLift",
    "total_cpm1",
    "total_cpm2",
  ];

  return (
    <Box
      id="table-total-row"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        position: "sticky",
        //top: "52px",
        zIndex: 1000,
        backgroundColor: colors.primaryCta10,
        borderBottom: "1px solid #ddd",
        overflow: "visible",
        //paddingBottom: "6px",
      }}
    >
      <Box sx={{ display: "flex", width: "100%", paddingLeft: "0px" }}>
        {Object.keys(summaryRowsT).length ? (
          <Grid
            container
            wrap="nowrap"
            sx={{ padding: "7px 0 7px 0", width: "100%", display: "flex", height: "36px" }}
          >
            {columnsModel?.map(({ field, summaryRowName, printToSummaryRow, unit = "", flex }, index) => {
              // Check if the column should be visible
              if (
                hiddenColumns[field] === false ||
                printToSummaryRow === false ||
                !(summaryRowName in summaryRowsT)
              ) {
                return null;
              }
              if (field === "currency") {
                return (
                  <Grid id="table-total-cell" data-field={field} item key={index} sx={{ flex }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        backgroundColor: "white",
                        borderRadius: "6px",
                        height: "100%",
                        boxShadow: "2px 2px 1px " + colors.mainDarkPrimary10,
                      }}
                    >
                      <Box sx={{ flex: 5, justifyContent: "center", display: "flex" }}>
                        <Typography id="currency-value" variant="body2XSmallMain600" noWrap textAlign="center">
                          {summaryCurrency}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "column", flex: 0.2 }}>
                        <Button
                          id="currency-up-btn"
                          startIcon={<KeyboardArrowUpIcon sx={{ fontSize: "small" }} />}
                          onClick={() => {
                            dispatch(switchSummaryCurrency("up"));
                          }}
                          sx={{
                            backgroundColor: "transparent",
                            minWidth: "12px",
                            height: "12px",
                            padding: 0,
                            "& .MuiButton-startIcon": {
                              margin: 0,
                            },
                          }}
                        ></Button>
                        <Button
                          id="currency-down-btn"
                          startIcon={<KeyboardArrowDownIcon sx={{ fontSize: "small" }} />}
                          onClick={() => {
                            dispatch(switchSummaryCurrency("down"));
                          }}
                          sx={{
                            backgroundColor: "transparent",
                            minWidth: "12px",
                            height: "12px",
                            padding: 0,
                            "& .MuiButton-startIcon": {
                              margin: 0,
                            },
                          }}
                        ></Button>
                      </Box>
                    </Box>
                  </Grid>
                );
              }

              let content = "";
              if (summaryRowNamesCurrencyDependent.includes(summaryRowName) && field !== "submitted_date")
                content = summaryRowsT[summaryRowName][summaryCurrency];
              else content = summaryRowsT[summaryRowName];
              //if (field === "submitted_date") content = "Total: ";
              let backgroundColor = "white";
              if (field === "submitted_date") backgroundColor = "transparent";
              if (field === "total_cpm1") backgroundColor = colors.primaryCta40;
              return (
                <Grid id="table-total-cell" data-field={field} item key={index} sx={{ flex }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: field === "submitted_date" ? "center" : "flex-end",
                      alignItems: "center",
                      padding: "0 10px 0 0 ",
                      backgroundColor,
                      borderRadius: "5px",
                      margin: "0 3px 0 3px",
                      height: "100%",
                      boxShadow: field === "submitted_date" ? 0 : "2px 2px 2px " + colors.mainDarkPrimary10,
                    }}
                    key={index}
                  >
                    <ConditionalWrapper
                      condition={field === "pct"}
                      wrapper={(children) => <EllipsisCell>{children}</EllipsisCell>}
                    >
                      {/* don't show tooltip when tooltipText is empty */}
                      <ConditionalWrapper
                        condition={content?.tooltipText}
                        wrapper={(children) => <BasicTooltip tooltipText={content.tooltipText}>{children}</BasicTooltip>}
                      >
                        <Typography variant="body2XSmallMain600" sx={{ cursor: "default" }}>
                          {content?.tableText}
                        </Typography>
                      </ConditionalWrapper>

                    </ConditionalWrapper>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        ) : null}
      </Box>
    </Box>
  );
}

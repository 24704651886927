import React, { useState, useEffect } from "react";
import { Box, PopoverVirtualElement } from "@mui/material";
import DropdownButton from "../Button/DropdownButton";
import Dropdown from "./Dropdown";
import { CategoryType } from "../../parametersConstancy/parametersConstancyTypes";

export interface DropdownHandlerProps {
  base: string;
  title: string;
  optionsData: CategoryType[];
  valuesData: CategoryType[];
  isDisabled?: boolean;
  openFilter: string;
  handleToggleOpen: (base: string) => void;
  handleApply: (base: string, selectedItems: CategoryType[]) => void;
  isMultiSelection: boolean;
  filtersIsLoading?: { [key: string]: boolean };
  shouldBeSelected?: boolean;
  isApplyDisabled?: boolean;
  notification?: string;
  id?: string;
}

const DropdownHandler: React.FC<DropdownHandlerProps> = React.memo((props) => {
  const {
    base,
    title,
    optionsData,
    valuesData,
    isDisabled = false,
    openFilter,
    handleToggleOpen,
    handleApply,
    isMultiSelection,
    filtersIsLoading = {},
    shouldBeSelected = false,
    isApplyDisabled = false,
    notification = "",
  } = props;

  const [checkAll, setCheckAll] = useState(optionsData?.length === valuesData?.length && valuesData?.length !== 0);
  const [options, setOptions] = useState<CategoryType[]>([]);
  const [selectedItems, setSelectedItems] = useState<CategoryType[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | PopoverVirtualElement | null>(null);
  const isOpened = openFilter === base;
  const [dropDownButtonTitle, setDropdomwButtonTitle] = useState<string>(title);

  useEffect(() => {
    setSelectedItems(valuesData);
  }, [valuesData]);

  useEffect(() => {
    setDropdomwButtonTitle(title);
  }, [title, options]);

  useEffect(() => {
    if (optionsData) {
      setOptions(optionsData);
    }
    if (valuesData) {
      if (!isMultiSelection && valuesData.length > 1) {
        const defaultValue = valuesData[0];
        setSelectedItems([defaultValue]);
      } else {
        setSelectedItems(valuesData);
        valuesData.length === optionsData.length && valuesData?.length !== 0
          ? setCheckAll(true)
          : setCheckAll(false);
      }
    }
  }, []);

  useEffect(() => {
    setCheckAll((isMultiSelection ?? false) && (checkAll ?? false));
    if (isMultiSelection) {
      setSelectedItems(checkAll ? optionsData : selectedItems.length > 0 ? selectedItems : []);
    } else if (valuesData.length > 1) {
      setSelectedItems([optionsData[0]]);
    }
  }, [checkAll]);

  useEffect(() => {
    if (!isOpened) {
      setInitValues();
    }
  }, [isOpened]);

  useEffect(() => {
    setDropdomwButtonTitle(selectedItems.length > 0 ? selectedItems[0].name : title);
  }, [selectedItems]);

  const setInitValues = () => {
    setOptions(optionsData);
    if (!isMultiSelection && valuesData.length > 1) {
      const defaultValue = valuesData[0];
      setSelectedItems([defaultValue]);
    } else {
      if (!isMultiSelection && shouldBeSelected) {
        if (valuesData.length > 1) {
          setSelectedItems([optionsData[0]]);
        } else {
          setSelectedItems(valuesData);
        }
      } else {
        setSelectedItems(valuesData);
        valuesData.length === optionsData.length && valuesData?.length !== 0
          ? setCheckAll(true)
          : setCheckAll(false);
      }
    }
  };

  const handleCheckAll = (checked: boolean) => {
    setCheckAll(checked);
    setSelectedItems(checked ? optionsData : []);
  };

  const handleClear = (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
    if (isOpened) {
      handleButtonClick(e);
    }
    e.preventDefault();
    e.stopPropagation();
    setCheckAll(false);
    handleApply(base, []);
  };

  const handleSearch = (value: string) => {
    const filteredOptions = optionsData.filter((option) => {
      return ("" + option.name).toLowerCase().includes(("" + value).toLowerCase());
    });
    setOptions(filteredOptions);
  };

  const handleSelect = (option: CategoryType) => {
    if (!isMultiSelection) {
      setSelectedItems([option]);
    } else {
      const selectedIndex = selectedItems.map((item) => item.id).indexOf(option.id);
      let newSelected = [...selectedItems];
      if (selectedIndex === -1) {
        newSelected.push(option);
      } else {
        if (!shouldBeSelected || newSelected.length !== 1) {
          newSelected.splice(selectedIndex, 1);
        }
      }
      newSelected.length === optionsData.length ? setCheckAll(true) : setCheckAll(false);
      setSelectedItems(newSelected);
    }
  };

  const handleClickAway = () => {
    handleToggleOpen("");
    setAnchorEl(null);
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (!isDisabled) {
      setAnchorEl(e.currentTarget || null);
      handleToggleOpen(base);
    }
    setInitValues();
  };

  return (
    <Box id={props.id}>
      {!filtersIsLoading[base] && (
        <DropdownButton
          id={`${props.id}-button`}
          title={dropDownButtonTitle}
          variant="filter"
          dropdownOpened={isOpened}
          isDisabled={isDisabled || filtersIsLoading[base]}
          selectedItemsLength={selectedItems?.length}
          buttoonCallback={handleButtonClick}
          clearIconCallback={handleClear}
          shouldBeSelected={shouldBeSelected}
        />
      )}
      <Dropdown
        dropdownOpened={openFilter === base}
        dropdownAnchorEl={anchorEl}
        allOptionsItemsLength={optionsData?.length}
        options={options}
        values={selectedItems}
        checkAll={checkAll}
        isMultiSelection={isMultiSelection}
        base={base}
        applyButtomClickCallback={handleApply}
        clearButtonClickCallback={handleClear}
        selectItemCallback={handleSelect}
        searchCallback={handleSearch}
        checkAllChangeCallback={handleCheckAll}
        handleClickAwayCallback={handleClickAway}
        isApplyDisabled={checkAll && isApplyDisabled}
        notification={checkAll ? notification : ""}
      />
    </Box>
  );
});
export default DropdownHandler;

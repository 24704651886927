import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { singleBtn } from "../../styles";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { getCsv } from "./export_csv-api";
import ExportReportIcon from "../../../../../components/IconComponents/ExportReportIcon";

export interface ExportMenuProps {
  activeFilters: any;
  hCols: any;
}

const ExportMenu = ({ hCols }: ExportMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [openBar, setOpenBar] = React.useState(false);
  const [textMessage, setTextMessage] = React.useState("");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleGenerateCsv = (event: React.MouseEvent<HTMLButtonElement>) => {
    setTextMessage("Generating IIQ report. Please wait a bit, will be ready in a moment...");
    setOpenBar(true);

    getCsv(hCols)
      .then(() => {
        setOpenBar(false);
        setTextMessage("CSV file was generated!");
        setOpenBar(true);
      })
      .catch((error: any) => {
        console.log(error);
        setOpenBar(false);
        const errorMessage = "Error while downloading CSV file...";
        setTextMessage(errorMessage);
        setOpenBar(true);
        alert(errorMessage);
      });

    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseBar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") return;
    setOpenBar(false);
  };

  const handleClickBar = () => {
    setOpenBar(true);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseBar}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Button
        id="table-export-btn"
        onClick={handleClick}
        endIcon={<ExportReportIcon />}
        sx={{
          ...singleBtn,
          // padding: "0.5rem 1rem",
          // color: "primary.ctaprimary100",
          // fontSize: "0.85rem",
          fontWeight: 400,
          // border: "1px solid #E0E2E7",
        }}
      >
        Export file
      </Button>
      <Popover
        id="export-menu-popup"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Typography id="export-to-csv-btn" sx={{ p: 2, cursor: "pointer" }} onClick={handleGenerateCsv}>
          Export to csv
        </Typography>
      </Popover>
      <Snackbar
        open={openBar}
        autoHideDuration={3000}
        onClose={handleCloseBar}
        message={textMessage}
        action={action}
      />
    </div>
  );
};
export default ExportMenu;

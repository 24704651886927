import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import { CONSTANT } from "../constants/constants";

export const PARAMETERS_TYPE_PAGE_MAP = {
  commonUserPlatformParameters: null,
  overviewParameters: CONSTANT.PAGES.OVERVIEW,
  performanceReportParameters: CONSTANT.PAGES.PERFORMANCE_REPORT,
  accountsParameters: CONSTANT.PAGES.ACCOUNTS,
};
export interface PlatformParameters {
  currentPlatformParameters: ParametersTypes;
  changedParameters: ChangedParameters;
  currentTemplate: { id: number; name: string };
  isStoring: boolean;
  isFetched: boolean;
  storeError: string | null;
}

export type Page = {
  id: number;
  name: string;
  path: string;
};

export const tablesRowsAmountPerPageOptions = [25, 50, 100];
export interface CommonUserPlatformParameters {
  currentPage: Page;
  selectedPartnersDummyIds: number[];
  currentMultiselectGroupId: number | null;
  customDateRange: CustomDateRange;
  dateGroupingMode: number;
  dateRange: DateRangeType;
  isPresentationModeEnabled: boolean;
  tablesRowsAmountPerPage: number;
}

export type DateRangeType = {
  type: string;
  value: number;
};

export type CurrencyDisplay = {
  [currency: string]: boolean;
};
export enum GroupType {}
export interface FilterGroup {
  id?: number;
  name: string;
  filters: {
    [filterName: string]: { ids: (number | string)[]; names: string[] };
  };
  partners: CategoryType[];
  type: string;
}

export interface PerformanceReportParameters {
  activeAreaChartType: string;
  areaChartCurrenciesToDisplay: CurrencyDisplay;
  filters: CategoriesType;
  activeFilterGroupId: number | null;
  columnVisibilityModel: Object;
  columnVisibilityModelVR: GridColumnVisibilityModel;
}
export interface OverviewParameters {
  groupType: string;
  chartType: number;
  chartBase: string;
  chartBaseUnits: ChartBaseUnits;
  reporterType: number;
  selectedPct: number | null;
  selectedCurrency: string | null;
  settingsStrictMode: boolean;
}
export interface AccountsParameters {
  accountsTableView: boolean;
}
export interface ParametersTypes {
  commonUserPlatformParameters: CommonUserPlatformParameters;
  performanceReportParameters: PerformanceReportParameters;
  overviewParameters: OverviewParameters;
  accountsParameters: AccountsParameters;
}
export interface ChangedParameters {
  commonUserPlatformParameters: { [K in keyof CommonUserPlatformParameters]?: CommonUserPlatformParameters[K] };
  performanceReportParameters: { [K in keyof PerformanceReportParameters]?: PerformanceReportParameters[K] };
  overviewParameters: { [K in keyof OverviewParameters]?: OverviewParameters[K] };
  accountsParameters: { [K in keyof AccountsParameters]?: AccountsParameters[K] };
}
export interface CustomDateRange {
  customDateStart: Date | null;
  customDateEnd: Date | null;
}
export interface ChartBaseUnits {
  [chartBase: string]: {
    units: any[];
    unit: any[];
  };
}

export type RequestPeriodType = {
  period: string;
  from: Date;
  to: Date;
};

export interface CategoryType {
  id: number | string;
  name: string;
  isSelected?: boolean;
  isChecked?: boolean;
  isReporting?: boolean;
}

export interface CategoriesType {
  [category: string]: CategoryType[];
}
export type ParametersKeyType = keyof (
  | CommonUserPlatformParameters
  | OverviewParameters
  | PerformanceReportParameters
  | AccountsParameters
);
export interface UserParametersConstancyPageType {
  commonUserPlatformParameters: CommonUserPlatformParameters;
  overviewParameters: OverviewParameters;
  performanceReportParameters: PerformanceReportParameters;
  accountsParameters: AccountsParameters;
}
export interface ResponseParametersDto extends UserParametersConstancyPageType {
  currentTemplate: { id: number; name: string };
}
export interface ChangedParameterSlicePayload<
  T extends keyof UserParametersConstancyPageType,
  K extends keyof UserParametersConstancyPageType[T],
> {
  blockType: T;
  parametersKey: K;
  payload: UserParametersConstancyPageType[T][K];
}

export type UserParametersConstancyDtoPayload = {
  parametersKey: ParametersKeyType;
  value: UserParametersConstancyPageType[ParametersKeyType];
};
export interface PageChangedParametersDto {
  commonUserPlatformParameters:
    | { [K in keyof CommonUserPlatformParameters]: CommonUserPlatformParameters[K] }
    | {};
  performanceReportParameters: { [K in keyof PerformanceReportParameters]: PerformanceReportParameters[K] } | {};
  overviewParameters: { [K in keyof OverviewParameters]: OverviewParameters[K] } | {};
  accountsParameters: { [K in keyof AccountsParameters]: AccountsParameters[K] } | {};
}
export interface UserParametersConstancyRequestDto {
  templateId: number;
  changedParameters: PageChangedParametersDto;
}
export const COMPOSITE_SETTINGS_KEYS = {
  OVERVIEW: "chartBaseUnits",
  PERFORMANCE_REPORT: "filters",
};

import { convertLocal, nFormatter, currencySymbols } from "../../utils";
import DefaultIcon from "../IconComponents/DefaultIcon";
import SearchIcon from "../IconComponents/SearchIcon";
import { Box } from "@mui/material";
import { CONSTANT } from "../../constants/constants";
import getSymbolFromCurrency from "currency-symbol-map";

import "../../core/performanceReports/advancedReport/teststyle.css";

export const BARS_AMOUNT = 12;

const renderTooltipInnerItem = (title, value, color) => {
  return (
    <div id="tooltip-item" style={{ display: "flex", fontSize: "1rem", marginBottom: "7px" }}>
      <p
        id="tooltip-item-dot"
        style={{
          color: color,
          paddingRight: 5,
          marginTop: 0,
          marginBottom: 0,
        }}
      >
        &#11044;
      </p>
      <p id="tooltip-item-title" style={{ marginTop: 0, marginBottom: 0 }}>{title}: </p>
      <p
        id="tooltip-item-value"
        style={{
          fontWeight: "600",
          marginLeft: "5px",
          marginTop: 0,
          marginBottom: 0,
        }}
      >
        {value}
      </p>
    </div>
  );
};

export const CustomTooltip = (props) => {
  let { active, payload, currency, apt } = props;
  let currencyStr = getSymbolFromCurrency(currency) || currency;
  if (active && payload && payload.length) {
    let name = payload[0].payload.name;
    return (
      <div
        id="stacked-bar-chart-tooltip"
        style={{
          backgroundColor: "#fff",
          minWidth: 170,
          padding: 10,
          borderRadius: 8,
          border: "1px solid rgba(0, 56, 93, 0.075)",
          color: "#00385D",
          fontSize: "0.9rem",
        }}
      >
        <p
          id="tootlip-title"
          style={{
            color: "#001738",
            marginTop: 0,
            marginBottom: "20px",
            fontWeight: "600",
          }}
        >
          {name}
        </p>
        {renderTooltipInnerItem(
          "Imp with IIQ",
          `${convertLocal(payload[0].payload.gr_a_imp.toFixed(1))}`,
          payload[1].fill,
        )}
        {renderTooltipInnerItem(
          "Imp without IIQ",
          `${convertLocal(payload[1].payload.gr_b_imp.toFixed(1))}`,
          payload[0].fill,
        )}
        {apt ? (
          <>
            <hr />
            {renderTooltipInnerItem(
              "Revenue with IIQ",
              `${currencyStr}${convertLocal(payload[0].payload.gr_a_cash.toFixed(1))}`,
              payload[1].fill,
            )}
            {renderTooltipInnerItem(
              "Revenue without IIQ",
              `${currencyStr}${convertLocal(payload[1].payload.gr_b_cash.toFixed(1))}`,
              payload[0].fill,
            )}
          </>
        ) : null}
      </div>
    );
  }
  return null;
};

export const getVal = (obj, key) => {
  return obj[key];
};

export const formatNumbers = (value, currency, applyNFormater = false) => {
  let currencyUnit = getSymbolFromCurrency(currency) || currency;

  if (applyNFormater) return `${currencyUnit}${nFormatter(value, 1)}`;
  return `${currencyUnit}${value.toFixed(2)}`;
};

export const renderChartLoader = (title = "...", subtitle = "", icon = <SearchIcon />) => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "400px",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {icon ? icon : null}
      <div style={{ fontSize: subtitle ? "18px" : "16px" }}>{title}</div>
      {subtitle ? (
        <div
          style={{
            marginTop: "10px",
            padding: "10px",
            backgroundColor: "rgba(27, 178, 255, 0.15)",
            borderRadius: "5px",
          }}
        >
          {subtitle}
        </div>
      ) : null}
    </Box>
  );
};

export const renderBars = (props, isPresentationModeEnabled, filterBase) => {
  let lines = [];
  for (let i = 0; i < props.unit.length; i++) {
    for (let k = 0; k < props.data.length; k++) {
      if (props.data[k].name === props.unit[i].partnerInstaceId) {
        let lineEntity = props.data[k];
        if (
          isPresentationModeEnabled &&
          filterBase !== CONSTANT.OVERVIEW.CHART_BASES.CLIENT_TYPE &&
          filterBase !== CONSTANT.OVERVIEW.CHART_BASES.BIDDERCODE
        ) {
          lineEntity = { ...lineEntity, name: props.unit[i].unitId };
        }
        lines.push(lineEntity);
      }
    }
  }
  return lines.sort((a, b) => b.gr_a_cash - a.gr_a_cash);
};

export const renderColorfulLegendText = (value, bars) => {
  //not in use
  let valStr = "";
  let obj = {};
  if (!bars.length) return;
  Object.keys(bars[0]).forEach((i) => (obj[i] = i));

  let t = value(obj);

  switch (t) {
    case "gr_b_cash":
      valStr = "Without IIQ";
      break;

    case "gr_a_cash":
      valStr = "With IIQ";
      break;

    case "ep_cash":
      valStr = "Revenue extrapolation 100% without IIQ";
      break;

    default:
      valStr = value;
  }
  return <span style={{ color: "#00385D", fontSize: "1rem" }}>{valStr}</span>;
};

export const renderDefaultIcon = (x, y, name) => {
  return name ? (
    <g transform="translate(-15 0)">
      <DefaultIcon x={x} y={y} text={name[0].toUpperCase()} title={name} />
    </g>
  ) : (
    <></>
  );
};

export const SvgIcon = ({ name, x, y, collection, renderDefaultIcons, isConcisedView, parentCompanyNames }) => {
  let translateX = -30;
  let translateY = -5;
  let height = 30;
  let svgName = name;

  if (collection === "biddercode") {
    translateX = -35;
    translateY = -7;
    height = 45;

    for (let i of parentCompanyNames) {
      if (Boolean(i) && name.includes(i)) {
        svgName = i;
      }
    }
  }

  // if (isConcisedView) height = 40; //check
  if (!renderDefaultIcons) {
    return renderDefaultIcon(x, y, name);
  }

  try {
    const Icon = require(`../../assets/svgIcons/${svgName}.svg`).ReactComponent;
    return (
      <g transform={`translate(${translateX} ${translateY})`}>
        <Icon id={`item_id_${name}`} x={x} y={y} height={height} />
      </g>
    );
  } catch (err) {
    return renderDefaultIcon(x, y, name);
  }
};

// NOT IN USE - works with svg library
// export const RenderIcon = (props) => {
//   const { x, y, payload, collection, renderDefaultIcons, isConcisedView } = props;
//   let translateX = -17;
//   let height = 30;

//   if (collection == "biddercode") translateX = -40;
//   if (isConcisedView) height = 40;
//   if (renderDefaultIcons && collection in svgLibrary) {
//     for (let i of svgLibrary[collection]) {
//       if (i.name == payload.value.trim().toLowerCase()) {
//         let Component = i.icon;
//         return (
//           <g transform={`translate(${translateX} 0)`} >
//             <Component x={x} y={y} height={height} />
//           </g >)
//       }
//     }
//   }

//   return (
//     <g transform="translate(-15 0)">
//       <DefaultIcon x={x} y={y} text={payload.value[0].toUpperCase()} title={payload.value} />
//     </g>
//   )
// }

export const CustomizedLabel = (props) => {
  let { shouldRotateItems, matches, currency, shiftDx, offsetTop, applyNFormater = true } = props;
  let { value, viewBox } = props.val;
  let textYPos = shouldRotateItems ? -5 : 10;
  let dx = shouldRotateItems ? 15 : matches ? shiftDx : 0;
  let rotate = shouldRotateItems ? " rotate(-45)" : matches ? "" : " rotate(-45)";
  let str = formatNumbers(value, currency, applyNFormater);
  let testStl = { fontSize: matches ? "1rem" : "0.85rem", fontWeight: 700 };

  return (
    <g>
      {/* don't remove */}
      {/* <rect
        transform={`translate(${viewBox.x + dx},${viewBox.y - 20})${rotate}`}
        fill="#aaa"
        width={100}
        height={30}
      /> */}
      <text
        id={props.id}
        className="fade-in" //see teststyle.css as additional css effect
        transform={`translate(${viewBox.x},${viewBox.y - textYPos})${rotate}`}
        dx={dx}
        fill="#131330"
        style={testStl}
      >
        {str}
      </text>
    </g>
  );
};

const findStringCombinations = (arr) => {
  // Find subsidiary - organization (bidder) that belongs to a parent or holding company
  // Appnexusgetmedia is subsidiary of Appnexus - share same svg icon.
  let res = {};
  for (let i = 0; i < arr.length - 1; i++) {
    if (res[arr[i]]) continue;
    let a = [];
    for (let j = i + 1; j < arr.length; j++) {
      if (res[arr[j]]) continue;
      if (arr[i].includes(arr[j]) || arr[j].includes(arr[i])) {
        a.push(arr[j]);
        a.push(arr[i]);
      }

      if (a.length) res[i] = a;
    }
  }
  return res;
};

const combineCommonStrings = (res) => {
  let commonStr = [];
  for (let i in res) {
    if (res[i].length === 0) continue;
    let shared = res[i].sort()[0];
    commonStr.push(shared);
  }
  return commonStr;
};

export const getSimilarNames = (arr) => {
  let res = findStringCombinations(arr);
  return combineCommonStrings(res);
};

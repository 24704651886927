import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Divider, IconButton } from "@mui/material";
import DropdownHandler from "../../../../components/Dropdown/DropdownHandler";
import { getStats } from "../getStats";
import {
  resetMainTableData,
  setActiveFilters,
  callsFilter,
  emptyFilters,
  performanceReportCategoriesFilters,
  visitortRecognitionCategoriesFilters,
  resetVRTData,
  setActiveFiltersByApllyGroup,
} from "../advancedReportSlice";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import auth from "../../../../auth/auth-helper";
import {
  resetRawPctAndRawCurrencyIndicators,
  filterByUnit,
  setActiveBase,
  setSummaryChartAsActiveChart,
  setActiveChart,
} from "../../../../report/reportSlice";
import { resetSavedRawDataState } from "../../../../savedRawData/savedRawDataSlice";
import { resetSavedRawDataPreviousPeriodState } from "../../../../savedRawDataPreviousPeriod/savedRawDataPreviousPeriodSlice";
import { resetSummaryChartRecords } from "../../../../summary/summarySlice";
import { useLocation } from "react-router-dom";
import { getVisitorRecognitionData } from "../../visitorRecognition/visitorRecognitionFetches";
import { CONSTANT, getCurrentPageIdByLocationPathname } from "../../../../constants/constants";
import { colors } from "../../../../theme";
import FilterGroupsIcon from "../../../../components/IconComponents/FilterGroupsIcon";
import ClearAllFiltersIcon from "../../../../components/IconComponents/ClearAllFiltersIcon";
import { BasicTooltip } from "../../../../components/BasicTooltip/BasicTooltip";
import LeftArrowIcon from "../../../../components/IconComponents/LeftArrowIcon";
import RightArrowIcon from "../../../../components/IconComponents/RightArrowIcon";
import FilterGroupsDropdown from "./FilterGroupsDropdown";
import { useGetFilterGroupsQuery } from "../../../../parametersConstancy/parametersContancyFetches";
import FilterGroupDialogForm, { DialogTypes } from "./FilterGroupDialogForm";
import AddIcon from "../../../../components/IconComponents/AddIcon";
import { applyPartners, resetMultiselectGroup } from "../../../../role/roleSlice";

const FILTERS_UNITS_BASES_MAP = {
  [CONSTANT.REPORTS.FILTERS_KEYS.BIDDERS.base]: CONSTANT.OVERVIEW.CHART_BASES.BIDDERCODE,
  [CONSTANT.REPORTS.FILTERS_KEYS.SITES.base]: CONSTANT.OVERVIEW.CHART_BASES.SITE_ID,
  [CONSTANT.REPORTS.FILTERS_KEYS.BROWSERS.base]: CONSTANT.OVERVIEW.CHART_BASES.CLIENT_TYPE,
};

const FILTERS_BASES_REPORTS_MAPS = [
  {
    chartBase: CONSTANT.OVERVIEW.CHART_BASES.BIDDERCODE,
    filterKey: CONSTANT.REPORTS.FILTERS_KEYS.BIDDERS.base,
  },
  { chartBase: CONSTANT.OVERVIEW.CHART_BASES.SITE_ID, filterKey: CONSTANT.REPORTS.FILTERS_KEYS.SITES.base },
  {
    chartBase: CONSTANT.OVERVIEW.CHART_BASES.CLIENT_TYPE,
    filterKey: CONSTANT.REPORTS.FILTERS_KEYS.BROWSERS.base,
  },
];

const arrowButtonStyle = {
  display: "flex",
  width: "40px",
  height: "40px",
  padding: "8px",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: 0,

  background: "#E9F2FE",
  boxShadow: "4px -4px 10px 4px rgba(0, 0, 0, 0.05)",
  ":hover": {
    background: "#E9F2FE",
    boxShadow: "4px -4px 10px 4px rgba(0, 0, 0, 0.05)",
  },
  position: "absolute",
  whiteSpace: "nowrap",
  minWidth: "40px", // Adjust this value as needed
  zIndex: 1,
};

const FiltersHandler = () => {
  const { filters, filtersIsLoading } = useSelector((state) => state.advancedReport);
  const { dateRange, customDateRange, dateGroupingMode } = useSelector(
    (state) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters
  );
  const timeGrouping = dateRange.value;
  const activeFilters = Object.fromEntries(
    Object.entries(filters).map(([key, values]) => [key, values.filter((unit) => unit.isSelected)])
  );
  const { partnersSelected, partnersList } = useSelector((state) => state.role);

  const [openFilter, setOpenFilter] = useState("");
  const isAdminDataRef = useRef(auth.isAdminRole());
  const dateGroupModeRef = useRef(dateGroupingMode);
  const dateGroupModeSliceRef = useRef(dateGroupingMode);
  const dispatch = useDispatch();
  const location = useLocation();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogActionsType, setDialogActionsType] = useState("");
  const [anchorFilterGroupsDropdown, setAnchorElFilterGroupsDropdown] = useState(null);
  const openFilterGroupsDropdown = Boolean(anchorFilterGroupsDropdown);
  const { data: filterGroupsRaw } = useGetFilterGroupsQuery();
  const filterGroups = filterGroupsRaw?.filter((group) => group.type === CONSTANT.FILTER_GROUP_TYPE.FILTER);

  const [selectedFilterGroup, setSelectedFilterGroup] = useState(null);
  const scrollContainerRef = useRef(null);
  const scrollInterval = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [currentScrollWidth, setCurrentScrollWidth] = useState(0);

  const updateScrollButtons = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft + clientWidth + 1 < scrollWidth || scrollWidth < currentScrollWidth);
    }
  };

  const startScroll = (direction) => {
    stopScroll(); // Clear any existing intervals
    scrollInterval.current = setInterval(() => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollBy({
          left: direction === "left" ? -15 : 15, // Adjust this value for speed
          behavior: "auto",
        });
      }
    }, 16); // Roughly 60 frames per second
  };

  const stopScroll = () => {
    if (scrollInterval.current) {
      clearInterval(scrollInterval.current);
    }
  };

  useEffect(() => {
    if (scrollContainerRef.current) {
      const { scrollWidth } = scrollContainerRef.current;
      setCurrentScrollWidth(scrollWidth);
      updateScrollButtons();
    }
  }, [scrollContainerRef, activeFilters]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    updateScrollButtons();

    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", updateScrollButtons);
      window.addEventListener("resize", updateScrollButtons);

      return () => {
        scrollContainer.removeEventListener("scroll", updateScrollButtons);
        window.removeEventListener("resize", updateScrollButtons);
      };
    }
  }, []);

  const handleClickFilterGroupsButton = (event) => {
    setAnchorElFilterGroupsDropdown(event.currentTarget);
  };

  const handleClosesFilterGroupsDropdown = () => {
    setAnchorElFilterGroupsDropdown(null);
  };

  const handleClickApplyFilterGroup = (groupId) => {
    const applyingGroup = filterGroups.find((group) => group.id === groupId);
    if (applyingGroup) {
      const applyingFilters = applyingGroup.filters;
      const applyingPartners = applyingGroup.partners ?? [];
      const updatedActiveFilters = Object.fromEntries(
        Object.entries(applyingFilters)
          .filter(([key]) => {
            if (location.pathname === CONSTANT.PAGES.PERFORMANCE_REPORT.path) {
              return performanceReportCategoriesFilters.includes(key);
            }
            if (location.pathname === CONSTANT.PAGES.VISITOR_RECOGNITION.path) {
              return visitortRecognitionCategoriesFilters.includes(key);
            }
            return true; // Include all by default if no specific path match
          })
          .map(([key, { ids, names }]) => {
            if (ids[0] === CONSTANT.REPORTS.SELECTED_ALL_OBJECT.id) {
              const updatedValues = filters[key].map((unit) => ({
                ...unit,
                isSelected: true, // Set isSelected to true for all included filters
              }));
              return [key, updatedValues];
            } else {
              const filter = applyingFilters[key];
              const updatedValues = filter.ids
                // filters[key]
                // .filter((unit) => ids.includes(unit.id))
                .map((id, index) => ({
                  id,
                  name: filter.names[index],
                  isSelected: true, // Set isSelected to true for all included filters
                }));

              return [key, updatedValues];
            }
          })
      );
      if (location.pathname === CONSTANT.PAGES.PERFORMANCE_REPORT.path) {
        performanceReportCategoriesFilters.forEach((key) => {
          if (!applyingFilters.hasOwnProperty(key)) {
            updatedActiveFilters[key] = [];
          }
        });
      }
      if (location.pathname === CONSTANT.PAGES.VISITOR_RECOGNITION.path) {
        visitortRecognitionCategoriesFilters.forEach((key) => {
          if (!applyingFilters.hasOwnProperty(key)) {
            updatedActiveFilters[key] = [];
          }
        });
      }
      if (applyingPartners.length > 0) {
        dispatch(resetMultiselectGroup());
      }
      applyFilters(updatedActiveFilters, applyingPartners);
      handleFilterChange(
        CONSTANT.REPORTS.FILTERS_KEYS.SITES.base,
        updatedActiveFilters[CONSTANT.REPORTS.FILTERS_KEYS.SITES.base],
        updatedActiveFilters
      );
    }
  };

  const handleClickOpenFilterGroupDialogForm = (dialogActionsType) => (groupId) => {
    setDialogActionsType(dialogActionsType);
    const filterGroupById = filterGroups?.find((group) => group.id === groupId);
    setSelectedFilterGroup(filterGroupById);
    setOpenDialog(true);
  };

  const handleCloseFilterGroupDialogForm = () => {
    setOpenDialog(false);
  };

  const handleToggleOpen = (base) => {
    setOpenFilter((prev) => (prev === base ? null : base));
  };

  const setupSelectedFilterToOverviewPage = (base, filterUnits) => {
    dispatch(setActiveBase(base));
    dispatch(setActiveChart(CONSTANT.OVERVIEW.CHART_TYPES.REVENUE_LIFT));

    const units = filterUnits.map((item) => ({
      partnerInstaceId: item.name,
      clientItemName: item.name,
      unitId: "" + item.id,
      color: `#${((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0")}`,
    }));
    dispatch(filterByUnit(units));
  };

  const handleFilterChange = (base, newSelectedValues, updatedActiveFilters) => {
    if (FILTERS_UNITS_BASES_MAP[base]) {
      if (newSelectedValues.length > 0) {
        if (newSelectedValues.length === updatedActiveFilters[base].length) {
          newSelectedValues = [CONSTANT.REPORTS.SELECTED_ALL_OBJECT];
        }
        setupSelectedFilterToOverviewPage(FILTERS_UNITS_BASES_MAP[base], newSelectedValues);
      } else {
        const activeFilter = FILTERS_BASES_REPORTS_MAPS.find(
          (filter) => updatedActiveFilters[filter.filterKey] && updatedActiveFilters[filter.filterKey].length > 0
        );
        if (activeFilter) {
          setupSelectedFilterToOverviewPage(activeFilter.chartBase, updatedActiveFilters[activeFilter.filterKey]);
        } else {
          dispatch(setSummaryChartAsActiveChart());
        }
      }
    } else {
      dispatch(setSummaryChartAsActiveChart());
    }
  };

  const handleApply = (base, newSelectedValues, resetAll = false) => {
    const updatedActiveFilters = !resetAll
      ? {
          ...activeFilters,
          [base]: newSelectedValues.map((item) => ({ ...item, isSelected: true })),
        }
      : {
          ...emptyFilters,
          calls: [{ id: 0, name: "All", isSelected: true }],
        };

    applyFilters(updatedActiveFilters, []);
    handleFilterChange(base, newSelectedValues, updatedActiveFilters);
  };

  const applyFilters = async (filtersPayload, applyingPartners) => {
    const applyingPartnersIds = applyingPartners.map((partner) => partner.id);
    const applyingMappedPartners = applyingPartners.map((partner) => {
      return {
        dummy_id: partner.id,
        partner_name: partner.name,
        isSelected: partner.isSelected,
      };
    });
    updateScrollButtons();

    const abortController = new AbortController();
    const signal = abortController.signal;

    if (location.pathname === CONSTANT.PAGES.VISITOR_RECOGNITION.path) {
      dispatch(resetVRTData());
      let payload = { ...filtersPayload };
      if (filtersPayload.platforms.length === 0) {
        if (filtersPayload.calls.length === 0) {
          payload = { ...payload, [CONSTANT.REPORTS.FILTERS_KEYS.CALLS.base]: [callsFilter[0]] };
        }
      }
      if (filtersPayload.platforms.length !== 0) {
        payload = { ...payload, [CONSTANT.REPORTS.FILTERS_KEYS.CALLS.base]: [] };
      }
      if (applyingPartnersIds.length === 0) {
        dispatch(setActiveFilters(payload));
        dispatch(
          getVisitorRecognitionData({
            requestData: {
              ...payload,
              dgm: dateGroupingMode,
              timeGrouping: timeGrouping,
              customDateRange: customDateRange,
              partnersIds: partnersSelected.map((p) => p.dummy_id), //MAP?
            },
            signal,
          })
        );
      } else {
        await Promise.resolve(dispatch(setActiveFiltersByApllyGroup(payload)));
        dispatch(applyPartners(applyingMappedPartners));
      }
    } else {
      dispatch(resetSavedRawDataState());
      dispatch(resetSavedRawDataPreviousPeriodState());
      dispatch(resetRawPctAndRawCurrencyIndicators());
      dispatch(resetSummaryChartRecords());
      dispatch(resetMainTableData());
      //and reset all saved raw datas
      // dispatch(resetSummaryCompleteDate());
      if (applyingPartnersIds.length === 0) {
        dispatch(setActiveFilters(filtersPayload));
        dispatch(getStats({ ...filtersPayload, timeGrouping: timeGrouping, customDateRange: customDateRange }));
      } else {
        await Promise.resolve(dispatch(setActiveFiltersByApllyGroup(filtersPayload)));
        dispatch(applyPartners(applyingMappedPartners));
      }
    }

    return function cleanup() {
      abortController.abort();
    };
  };

  if (isAdminDataRef.current && !partnersSelected.length > 0) {
    return (
      <div
        style={{
          height: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ position: "relative" }}>
          <div style={{ position: "absolute", top: "-5px", left: "-30px" }}>
            <WarningAmberRoundedIcon sx={{ color: "orange" }} />
          </div>
          Please, define partner to continue...
        </div>
      </div>
    );
  }

  let canSaveGroup = false;
  const curentPageId = getCurrentPageIdByLocationPathname(location.pathname);

  const mappedFilters = Object.values(CONSTANT.REPORTS.FILTERS_KEYS).map((category) => {
    if (category.pagesIds && category.pagesIds.includes(curentPageId)) {
      const title = category.title;
      const categoryBase = category.base;
      const selectAllPartners = partnersSelected.length === partnersList.length;
      const bigTimeRange =
        timeGrouping === CONSTANT.DATE_RANGE_TYPES.ALL_TIME.value ||
        timeGrouping === CONSTANT.DATE_RANGE_TYPES.YTD.value;
      const forbiddenFiltersConfiguration =
        selectAllPartners && categoryBase === CONSTANT.REPORTS.FILTERS_KEYS.SITES.base && bigTimeRange;
      let isDisabled = false;
      canSaveGroup = canSaveGroup || activeFilters[categoryBase].length > 0;

      if (curentPageId === CONSTANT.PAGES.VISITOR_RECOGNITION.id) {
        isDisabled =
          (categoryBase === CONSTANT.REPORTS.FILTERS_KEYS.ACTION_TERMINATIONS.base &&
            activeFilters.platforms.length > 0) ||
          (categoryBase === CONSTANT.REPORTS.FILTERS_KEYS.PLATFORMS.base &&
            activeFilters.actionTerminations.length > 0) ||
          (categoryBase === CONSTANT.REPORTS.FILTERS_KEYS.CALLS.base && activeFilters.platforms.length > 0) ||
          (categoryBase === CONSTANT.REPORTS.FILTERS_KEYS.SITES.base && activeFilters.countries.length > 0) ||
          (categoryBase === CONSTANT.REPORTS.FILTERS_KEYS.COUNTRIES.base && activeFilters.sites.length > 0);
      }
      //console.log("forbiddenFiltersConfiguration: ", forbiddenFiltersConfiguration);

      if (
        curentPageId === CONSTANT.PAGES.PERFORMANCE_REPORT.id &&
        dateGroupingMode === CONSTANT.DATE_GROUP_MODES.HOUR &&
        categoryBase === CONSTANT.REPORTS.FILTERS_KEYS.SITES.base
      )
        isDisabled = true;
      let notification = "";
      if (forbiddenFiltersConfiguration)
        notification = "Please reduce the number of selected partners or time range to select all sites";
      return (
        <DropdownHandler
          id={`${categoryBase}-filter-dropdown`}
          key={categoryBase}
          base={categoryBase}
          title={title}
          optionsData={filters[categoryBase]}
          valuesData={isDisabled ? [] : activeFilters[categoryBase]}
          openFilter={openFilter}
          handleToggleOpen={handleToggleOpen}
          handleApply={handleApply}
          isDisabled={isDisabled}
          isMultiSelection={categoryBase !== CONSTANT.REPORTS.FILTERS_KEYS.CALLS.base}
          shouldBeSelected={
            categoryBase === CONSTANT.REPORTS.FILTERS_KEYS.CALLS.base && activeFilters.platforms.length === 0
          }
          filtersIsLoading={filtersIsLoading}
          isApplyDisabled={forbiddenFiltersConfiguration}
          notification={notification}
        />
      );
    }
  });

  return (
    <Box
      id="filters-handler-container"
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        // width: "100%",
        // flexWrap: "wrap",
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          overflow: "auto",
        }}
      >
        {canScrollLeft && (
          <Button
            // onClick={scrollLeft}
            onMouseEnter={() => startScroll("left")}
            onMouseLeave={stopScroll}
            sx={{
              ...arrowButtonStyle,
              borderRadius: "0px 16px 16px 0px",
              left: 0,
            }}
            id="filters-handler-left-btn"
          >
            <LeftArrowIcon />
          </Button>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            gap: "12px",
            overflow: "auto",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            overflowY: "hidden",

            "&::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none", // Internet Explorer 10+
            scrollbarWidth: "none", // Firefox
          }}
          ref={scrollContainerRef}
        >
          {mappedFilters}
        </Box>
        <Button
          // onClick={scrollRight}

          onMouseEnter={() => startScroll("right")}
          onMouseLeave={stopScroll}
          sx={{
            ...arrowButtonStyle,
            borderRadius: "16px 0px 0px 16px",
            right: 0,
            scale: canScrollRight ? "" : "1%",
            opacity: canScrollRight ? 1 : 0,
            transition: "opacity 0.3s ease",
          }}
          id="filters-handler-right-btn"
        >
          <RightArrowIcon />
        </Button>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "row", gap: "4px", justifyContent: "center", alignItems: "center" }}
      >
        <IconButton onClick={(e) => handleClickFilterGroupsButton(e)} id="filter-groups-btn">
          <FilterGroupsIcon />
        </IconButton>
        <FilterGroupsDropdown
          open={openFilterGroupsDropdown}
          anchorEl={anchorFilterGroupsDropdown}
          handleClose={handleClosesFilterGroupsDropdown}
          handleClickOpenFilterGroupDialogEditForm={handleClickOpenFilterGroupDialogForm(DialogTypes.EDIT)}
          handleClickApplyFilterGroup={handleClickApplyFilterGroup}
          filterGroups={filterGroups}
        />
        <Divider orientation="vertical" flexItem />

        <Button
          variant="netural"
          disabled={!canSaveGroup}
          onClick={handleClickOpenFilterGroupDialogForm(DialogTypes.CREATE)}
          id="add-filters-group-btn"
          startIcon={<AddIcon color={canSaveGroup ? colors.iconBrandDefault : colors.netural200} />}
        >
          Group
        </Button>
        <FilterGroupDialogForm
          id="new-filter-group-modal"
          open={openDialog}
          closeDialog={handleCloseFilterGroupDialogForm}
          dialogActionsType={dialogActionsType}
          filterGroup={selectedFilterGroup}
          type={CONSTANT.FILTER_GROUP_TYPE.FILTER}
        />
        <Divider orientation="vertical" flexItem />

        <BasicTooltip tooltipText={"Clear all selected filters"} delay={500}>
          <Box>
            <Button
              id="clear-filters-btn"
              disabled={!canSaveGroup}
              startIcon={
                <ClearAllFiltersIcon color={canSaveGroup ? colors.iconBrandDefault : colors.netural200} />
              }
              variant="netural"
              onClick={() => {
                handleApply(null, null, true);
              }}
            >
              Clear all
            </Button>
          </Box>
        </BasicTooltip>
      </Box>
    </Box>
  );
};

export default FiltersHandler;

import { Box, Button, SxProps, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../theme";
import { OverridableStringUnion } from "@mui/types";
import { ButtonPropsVariantOverrides } from "@mui/material/Button/Button";
import ClearIcon from "../IconComponents/ClearIcon";
import ExpandLessIcon from "../IconComponents/ExpandLessIcon";
import ExpandMoreIcon from "../IconComponents/ExpandMoreIcon";
import { CONSTANT } from "../../constants/constants";

export interface DropdownButtonProps {
  style?: SxProps;
  title: string;
  variant: OverridableStringUnion<"text" | "outlined" | "contained", ButtonPropsVariantOverrides>;
  isDisabled: boolean;
  dropdownOpened: boolean;
  selectedItemsLength: number;
  buttoonCallback: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  clearIconCallback: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  shouldBeSelected?: boolean;
  [key: string]: any;
  base?: string;
}

const defaultStyle = (selectedItemsLength: number, dropdownOpened: boolean): SxProps => ({
  background: `${selectedItemsLength > 0 && colors.backgroudSelected}`,
  color: `${selectedItemsLength > 0 && colors.mainWhite}`,
  flexDirection: "row",
  whiteSpace: "nowrap",
  "&:hover": {
    background: `${selectedItemsLength > 0 && colors.foreground}`,
  },
});

const DropdownButton: React.FC<DropdownButtonProps> = ({
  title = "Dropdown Button Title",
  variant = "filter",
  dropdownOpened = false,
  isDisabled = false,
  selectedItemsLength = 0,
  buttoonCallback = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {},
  clearIconCallback = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {},
  style,
  shouldBeSelected = false,
  base = "",
  id,
}) => {
  const computedStyle = style || defaultStyle(selectedItemsLength, dropdownOpened);
  return (
    <Button
      id={id ? id : title}
      variant={variant}
      size="small"
      disabled={isDisabled}
      onClick={buttoonCallback}
      endIcon={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {selectedItemsLength > 0 && !shouldBeSelected ? (
            <Box id="dropdown-btn-clear-icon" sx={{ height: "24px" }} onClick={clearIconCallback}>
              <ClearIcon />
            </Box>
          ) : dropdownOpened ? (
            <ExpandLessIcon color={shouldBeSelected ? colors.mainWhite : ""} />
          ) : (
            <ExpandMoreIcon color={shouldBeSelected && selectedItemsLength > 0 ? colors.mainWhite : ""} />
          )}
        </Box>
      }
      sx={computedStyle}
    >
      {base === CONSTANT.REPORTS.PARTNERS.base
        ? title
        : selectedItemsLength > 1
          ? ` ${title} + ${selectedItemsLength - 1} more`
          : title}
    </Button>
  );
};

export default DropdownButton;
